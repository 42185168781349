define('ember-cli-bootstrap-datetimepicker/components/bs-datetimepicker', ['exports', 'ember', 'ember-cli-bootstrap-datetimepicker/templates/components/bs-datetimepicker'], function (exports, Ember, layout) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend({
    layout: layout['default'],
    tagName: 'div',
    classNames: ['input-group date'],

    setupDatepicker: Ember['default'].on('didInsertElement', function () {
      var _this = this;

      var defaults = Ember['default'].$.fn.datetimepicker.defaults;

      this.$().datetimepicker({
        date: this.getWithDefault('date', defaults.defaultDate),
        focusOnShow: this.getWithDefault('focusOnShow', defaults.focusOnShow),
        format: this.getWithDefault('format', defaults.format),
        locale: this.getWithDefault('locale', defaults.locale),
        maxDate: this.getWithDefault('maxDate', defaults.maxDate),
        minDate: this.getWithDefault('minDate', defaults.minDate),
        showClear: this.getWithDefault('showClear', defaults.showClear),
        showClose: this.getWithDefault('showClose', defaults.showClose),
        showTodayButton: this.getWithDefault('showTodayButton', defaults.showTodayButton),
        viewMode: this.getWithDefault('viewMode', defaults.viewMode)
      }).on('dp.change', function (e) {
        // Convert moment to js date or default to null
        var newDate = e.date && e.date.toDate() || null;

        _this.set('date', newDate);
        _this.sendAction('change', newDate);
      });

      this.addObserver('date', function () {
        this.$().data('DateTimePicker').date(this.get('date'));
      });

      this.addObserver('maxDate', function () {
        this.$().data('DateTimePicker').maxDate(this.get('maxDate'));
      });

      this.addObserver('minDate', function () {
        this.$().data('DateTimePicker').minDate(this.get('minDate'));
      });
    }),

    destroyDatepicker: Ember['default'].on('willDestroyElement', function () {
      this.removeObserver('date');
      this.removeObserver('maxDate');
      this.removeObserver('minDate');

      this.$().data('DateTimePicker').destroy();
    })
  });

});