define('ember-cli-sticky/components/sticky-container', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend({
    tagName: 'div',
    classNames: 'sticky',
    topSpacing: 0,
    defaultOptions: Ember['default'].computed('topSpacing', function () {
      return {
        topSpacing: this.get('topSpacing')
      };
    }),

    mergedOptions: Ember['default'].computed('options', function () {
      return Ember['default'].merge(this.get('defaultOptions'), this.get('options'));
    }),

    setupSticky: Ember['default'].on('didInsertElement', function () {
      this.$().sticky(this.get('mergedOptions'));
    }),

    teardownSticky: Ember['default'].on('willDestroyElement', function () {
      this.$().unstick();
    })
  });

});