define('ember-inputmask/components/phone-number-input', ['exports', 'ember-inputmask/components/input-mask'], function (exports, InputMaskComponent) {

  'use strict';

  exports['default'] = InputMaskComponent['default'].extend({
    mask:    '(999) 999-9999',

    updateMask: function() {
      if (this.get('extensions')) {
        this.set('mask', '(999) 999-9999[ x 9{1,4}]');
      }

      this._super();
    }.observes('mask', 'extensions')
  });

});