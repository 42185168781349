define('ui-icon/components/ui-icon', ['exports', 'ember', 'ui-icon/templates/components/ui-icon'], function (exports, Ember, layout) {

  'use strict';

  var Component = Ember['default'].Component;
  var computed = Ember['default'].computed;
  var observer = Ember['default'].observer;
  var run = Ember['default'].run;

  exports['default'] = Component.extend({
    layout: layout['default'],
    tagName: 'i',
    classNames: ['ui-icon'],
    classNameBindings: ['fontFamily', '_fw', '_icon', '_classSize', 'spin:fa-spin', 'pulse:fa-pulse', 'border:fa-border', 'muted', 'cursor'],
    attributeBindings: ['_style:style'],
    fontFamily: 'fa',
    fw: true,
    _fw: Ember['default'].on('init', computed('fw', function () {
      var fw = this.get('fw');
      var fontFamily = this.get('fontFamily');
      return fw ? fontFamily + '-fw' : null;
    })),
    icon: null,
    _icon: computed('icon', function () {
      var family = this.get('fontFamily');
      var icon = this.get('icon');
      return icon ? family + '-' + icon : null;
    }),
    color: null,
    border: false,
    circular: false,
    padded: false,
    _circular: observer('circular', function () {
      var _this = this;

      var circular = this.get('circular');
      var padded = this.get('padded');
      if (circular) {
        if (Number(padded) !== padded) {
          padded = padded ? 10 : 0;
        }
        run.next(function () {
          var height = _this.$().height();
          _this.set('_styleWidth', height + padded + 'px');
          _this.set('_styleHeight', height + padded + 'px');
          _this.set('_stylePadTop', padded / 2 + 'px');
          _this.set('_stylePadLeft', padded / 2 * (1 / 3) + 'px');
          _this.set('_styleBorderRadius', '50%');
          _this.set('_styleTextAlign', 'center');
        });
      }
    }),
    size: null, // set by container
    _classSize: null,
    _styleFontSize: null,
    _styleBorderRadius: null,
    _styleWidth: null,
    _styleHeight: null,
    _stylePadLeft: null,
    _stylePadTop: null,
    _sizeObserver: observer('size', function () {
      var size = String(this.get('size'));
      if (size.substr(-2) === 'pt' || size.substr(-2) === 'em' || size.substr(-1) === '%') {
        this.set('_styleFontSize', size);
      } else if (size && size === 'lg') {
        this.set('_classSize', 'fa-lg');
      } else if (size && size.substr(-1) === 'x') {
        // fontsize multiplier
        var factor = size.substr(0, size.length - 1);
        if (factor > 10) {
          console.log('ui-icon has styling options up to 10x, not as high as %s. If you need bigger sizing use an explicit pixel size(px) or state a percentage number rather than a multiplier', factor);
        }
        if (factor) {
          this.set('_classSize', 'scale-' + factor + 'x');
        }
      } // end scale factor
    }),
    value: null, // values are passed by an action
    _value: computed('value', 'elementId', function () {
      var _getProperties = this.getProperties('value', 'elementId');

      var value = _getProperties.value;
      var elementId = _getProperties.elementId;

      return value !== null ? value : elementId;
    }),
    click: function click() {
      var value = this.get('_value');
      this.sendAction('action', value); // general action
    },

    tooltip: false,
    tooltipPlacement: 'top',
    tooltipDelay: 500,
    tooltipHtml: true,
    tooltipTrigger: 'hover',
    tooltipTemplate: '<div class="tooltip" role="tooltip"><div class="tooltip-arrow"></div><div class="tooltip-inner"></div></div>',
    _tooltipInit: Ember['default'].on('didInsertElement', function () {
      var _this2 = this;

      var tooltip = this.get('tooltip');
      if (tooltip) {
        (function () {
          var _getProperties2 = _this2.getProperties('tooltipPlacement', 'tooltipDelay', 'tooltipHtml', 'tooltipTrigger', 'tooltipTemplate');

          var placement = _getProperties2.tooltipPlacement;
          var delay = _getProperties2.tooltipDelay;
          var html = _getProperties2.tooltipHtml;
          var trigger = _getProperties2.tooltipTrigger;
          var template = _getProperties2.tooltipTemplate;

          Ember['default'].run.next(function () {
            try {
              _this2.$().tooltip({
                title: tooltip,
                delay: delay,
                html: html,
                trigger: trigger,
                placement: placement,
                template: template
              });
            } catch (e) {
              console.log('There was a problem setting up the tooltip on [' + _this2.get('elementId') + '], ensure Bootstrap\'s JS is included in the vendor JS.\n%o', e);
            }
          });
        })();
      }
    }),
    _style: Ember['default'].computed('_styleWidth', '_styleFontSize', '_styleTextAlign', '_stylePadTop', '_stylePadLeft', '_styleBorderRadius', '_styleFontSize', 'color', function () {
      var _this3 = this;

      var propMap = [{ key: '_styleWidth', value: 'width' }, { key: '_styleHeight', value: 'height' }, { key: 'color', value: 'color' }, { key: 'background', value: 'background-color' }, { key: '_styleBorderRadius', value: 'border-radius' }, { key: '_styleTextAlign', value: 'text-align' }, { key: '_stylePadTop', value: 'padding-top' }, { key: '_stylePadLeft', value: 'padding-left' }, { key: '_styleFontSize', value: 'font-size' }];
      var style = '';
      propMap.forEach(function (item) {
        var styleProperty = _this3.get(item.key);
        if (styleProperty) {
          style = style + (';' + item.value + ':' + styleProperty);
        }
      });

      return Ember['default'].String.htmlSafe(style);
    }),

    _init: Ember['default'].on('didInsertElement', function () {
      this._sizeObserver();
      this._circular();
    })

  });

});