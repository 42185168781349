define('ember-cli-bootstrap-datepicker/components/datepicker-support', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Mixin.create({
    mustUpdateInput: true,
    value: null,

    setupBootstrapDatepicker: Ember['default'].on('didInsertElement', function () {
      var self = this;

      this.$().datepicker({
        autoclose: this.get('autoclose'),
        calendarWeeks: this.get('calendarWeeks'),
        clearBtn: this.get('clearBtn'),
        container: this.get('widgetContainer'),
        daysOfWeekDisabled: this.get('daysOfWeekDisabled'),
        defaultViewDate: this.get('defaultViewDate'),
        disableTouchKeyboard: this.get('disableTouchKeyboard'),
        enableOnReadonly: this.get('enableOnReadonly'),
        endDate: this.get('endDate'),
        forceParse: this.get('forceParse'),
        format: this.get('format'),
        immediateUpdates: this.get('immediateUpdates'),
        keyboardNavigation: this.get('keyboardNavigation'),
        language: this.get('language'),
        maxViewMode: this.get('maxViewMode'),
        minViewMode: this.get('minViewMode'),
        multidate: this.get('multidate'),
        multidateSeparator: this.get('multidateSeparator'),
        orientation: this.get('orientation'),
        showOnFocus: this.get('showOnFocus'),
        startDate: this.get('startDate'),
        startView: this.get('startView'),
        todayBtn: this.get('todayBtn'),
        todayHighlight: this.get('todayHighlight'),
        toggleActive: this.get('toggleActive'),
        weekStart: this.get('weekStart')
      }).on('changeDate', function (event) {
        Ember['default'].run(function () {
          self._didChangeDate(event);
        });
      });

      this._updateDatepicker();
    }),

    teardownBootstrapDatepicker: Ember['default'].on('willDestroyElement', function () {
      this.$().datepicker('remove');
    }),

    didChangeValue: Ember['default'].observer('value', function () {
      this._updateDatepicker();
    }),

    _didChangeDate: function _didChangeDate(event) {
      var value = null;

      if (event.date) {
        if (this.get('multidate')) {
          value = this.$().datepicker('getDates');
        } else {
          value = this.$().datepicker('getDate');
        }
      }

      this.set('mustUpdateInput', false);
      this.set('value', value);
      this.sendAction('changeDate', value);
    },

    _didChangeLanguage: Ember['default'].observer('language', function () {
      this.$().datepicker('remove');
      this.setupBootstrapDatepicker();
    }),

    _updateStartDate: Ember['default'].observer('startDate', function () {
      this.$().datepicker('setStartDate', this.get('startDate'));
      this._updateDatepicker();
    }),

    _updateEndDate: Ember['default'].observer('endDate', function () {
      this.$().datepicker('setEndDate', this.get('endDate'));
      this._updateDatepicker();
    }),

    _updateDatepicker: function _updateDatepicker() {
      var self = this,
          element = this.$(),
          value = this.get('value'),
          dates = [];

      if (!this.get('mustUpdateInput')) {
        this.set('mustUpdateInput', true);
        return;
      }

      switch (Ember['default'].typeOf(value)) {
        case 'array':
          dates = value;
          break;
        case 'date':
          dates = [value];
          break;
        default:
          dates = [null];
      }
      dates = dates.map(function (date) {
        return Ember['default'].isNone(date) ? null : self._resetTime(date);
      });

      element.datepicker.apply(element, Array.prototype.concat.call(['update'], dates));
    },

    // HACK: Have to reset time to 00:00:00 because of the bug in
    //       bootstrap-datepicker
    //       Issue: http://git.io/qH7Hlg
    _resetTime: function _resetTime(date) {
      date.setHours(0);
      date.setMinutes(0);
      date.setSeconds(0);
      date.setMilliseconds(0);

      return date;
    }
  });

});