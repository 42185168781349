define('ember-inputmask/components/zip-code-input', ['exports', 'ember-inputmask/components/input-mask'], function (exports, InputMaskComponent) {

  'use strict';

  exports['default'] = InputMaskComponent['default'].extend({
    mask:    '99999',

    fullCode: false,

    updateMask: function() {
      if (this.get('fullCode')) {
        this.set('mask', '99999[-9999]');
      } else {
        this.set('mask', '99999');
      }

      this._super();
    }.observes('mask', 'fullCode')
  });

});