define('ui-icon/components/icon-desktop', ['exports', 'ui-icon/templates/components/icon-facebook', 'ui-icon/components/ui-icon'], function (exports, layout, UiIcon) {

  'use strict';

  // import Ember from 'ember';
  exports['default'] = UiIcon['default'].extend({
    layout: layout['default'],
    icon: 'desktop'
  });

});